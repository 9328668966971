import axios from 'axios';
import LocalStorageManager from '@/storages/LocalStorageManager';
import router from '@/router/'
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

http.interceptors.request.use(
  function(config) {
    checkConfig(config);
    return config;
  },
  function(error) {
    console.log('error Promise',error);
    return Promise.reject(error);
  },
);

function checkConfig(config) {
  const token = LocalStorageManager.shared.getToken() || config.headers.Authorization;
  const routeCurrentName = router.history.current.name;
  const isLoginPage = routeCurrentName === 'Login' || routeCurrentName === 'LoginDirect'
  if (!token) {
    if(!isLoginPage){
      router.push({ name : "LoginDirect" }).catch(()=>{});
    }
  }else{
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  // console.log(config.headers);
}

http.interceptors.response.use(
  async function(response) {
    return response;
  },
  function(error) {
    // -20010 ~ -20020
    if(error.response.data.code <= -20020 && error.response.data.code >= -20010){
      router.push({ name : "LoginDirect" }).catch(()=>{});
      LocalStorageManager.shared.clearToken();
      LocalStorageManager.shared.clearUserData();
      store.dispatch('commonUserData/fetchUserData', {});
      return Promise.reject(error);
    }
    // -20003
    else if(error.response.data.code === -20003){
      router.push({ name : "LoginDirect" }).catch(()=>{});
      store.dispatch('passowrdChangeError/fetchPasswordChangeErrorStart');
      return Promise.reject(error);
    }
    else{
      return Promise.reject(error);
    }
  },
);

export function GET_REQUEST(path) {
  return new Promise((success, failed) => {
    http.get(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      const errorData = err.response?.data;
      const result = errorData?.header?.result;

      let failResult = {
        errCode: result?.code || errorData.code,
        msg: result?.description || errorData.message || ''
      }
      failed(failResult)
    })
  })
}

export function PUT_REQUEST(path, data) {
  return new Promise((success, failed) => {
    http.put(path, data).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        // const description = data.header.result.description
        const errCode = '200'
        const errMsg = 'errMsg'
        const description = 'description'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg + '\n' + description
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      const errorData = err.response?.data;
      const result = errorData?.header?.result;

      let failResult = {
        errCode: result?.code || errorData.code,
        msg: result?.description || errorData.message || ''
      }
      failed(failResult)
    })
  })
}

export function POST_REQUEST(path, data, config) {
  return new Promise((success, failed) => {
    http.post(path, data, config).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      const errorData = err.response?.data;
      const result = errorData?.header?.result;

      let failResult = {
        errCode: result?.code || errorData.code,
        msg: result?.description || errorData.message || ''
      }
      failed(failResult)
    })
  })
}

export function DELETE_REQUEST(path) {
  return new Promise((success, failed) => {
    http.delete(path).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }
    }).catch((err) => {
      const errorData = err.response?.data;
      const result = errorData?.header?.result;

      let failResult = {
        errCode: result?.code || errorData.code,
        msg: result?.description || errorData.message || ''
      }
      failed(failResult)
    })
  })
}

export default http;
