import { serviceMenuData } from '@/constants/serviceMenuData';
import { contentMenuData } from '@/constants/contentMenuData';
import { csMenuData } from '@/constants/csMenuData';
// import { storeMenuData } from '@/constants/storeMenuData';
import { adminMenuData } from '@/constants/adminMenuData';
import { monitoringMenuData } from '@/constants/monitoringMenuData';
import { guideMenuData } from '@/constants/guideMenuData';

const menuData = [
  // {
  //   title: '대시보드',
  //   id: 'DASHBOARD',
  //   menuIcon: '🚥',
  //   routerPath: 'DASHBOARD_DASHBOARD',
  // },
  {
    title: '서비스 관리',
    foldTitle: '서비스',
    id: 'SERVICE',
    menuList: serviceMenuData,
    isFold: false,
  },
  {
    title: '컨텐츠 관리',
    foldTitle: '컨텐츠',
    id: 'CONTENT',
    menuList: contentMenuData,
    isFold: false,
  },
  {
    title: 'CX',
    foldTitle: 'CX',
    id: 'CS',
    menuList: csMenuData,
    isFold: false,
  },
  // {
  //   title: '레몬페이 가맹점 관리',
  //   foldTitle: '가맹점',
  //   id: 'STORE',
  //   menuList: storeMenuData,
  //   isFold: false,
  // },
  {
    title: '어드민 관리',
    foldTitle: '어드민',
    id: 'ADMIN',
    menuList: adminMenuData,
    requiresMinRole: 'ADMIN',
    isFold: true,
  },
  {
    title: '모니터링',
    foldTitle: '모니터링',
    id: 'MONITORING',
    menuList: monitoringMenuData,
    isFold: true,
  },
  {
    title: 'Guide',
    foldTitle: 'Guide',
    id: 'GUIDE',
    menuList: guideMenuData,
    isFold: true,
    isOnlyDev: true,
  },
];

export { menuData };
