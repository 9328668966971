// (더미)상태컬러
export const dummyStatusColorDataList = [
  { id: 'TODO', text: 'default' },
  { id: 'DOING', text: 'lightgreen' },
  { id: 'DONE', text: 'blue' },
  { id: 'HOLD', text: 'orange' },
];
export const dummyStatusIconColorDataList = [
  { id: 'TODO', text: '#959595' },
  { id: 'DOING', text: '#8BC34A' },
  { id: 'DONE', text: '#3F51B4' },
  { id: 'HOLD', text: '#FF7043' },
];
export const dummy_status_color = [
  { id: 'DRAFT', text: '#F04848' },
  { id: 'PUBLIC', text: '#388E3B' },
  { id: 'CLOSED', text: '#CCCCCC' },
];

// (더미)상태2
export const dummy2StatusColorDataList = [
  { id: 'High', text: 'red' },
  { id: 'Normal', text: 'green' },
  { id: 'Light', text: 'blue' },
  { id: 'None', text: 'default' },
];

export const common_status = [
  { id: 'REGISTERED', text: 'green' },
  { id: 'UNREGISTERED', text: 'red' },
];

// 서비스 > 회원관리
export const user_status_name = [
  // { id:'ready', text:'대기중' },
  // { id:'active', text:'활성화' },
  // { id:'quit', text:'탈퇴' },
  // { id:'inacitve', text:'휴면' },
  // { id:'restricted', text:'제재' },
  { id: 'EXPIRED', text: 'gray' },
  { id: 'PENDING', text: 'yellow' },
  { id: 'REGISTERED', text: 'green' },
  { id: 'UNREGISTERED', text: 'red' },
];

// 서비스 > 멤버십관리
export const membership_type = [
  { id: 'PLUS_USER', text: 'purple' },
  { id: 'BANK_ASSOCIATION', text: 'blue' },
];

export const membership_purchase_method_type = [
  { id: 'FREE', text: 'skyblue' },
  { id: 'REDEEM', text: 'pink' },
  { id: 'APPLE', text: 'brown' },
  { id: 'GOOGLE', text: 'orange' },
  { id: 'IMWEB', text: 'green' },
];

export const membership_payment_status = [
  { id: 'PAYMENT', text: 'blue' },
  { id: 'CANCEL', text: 'red' },
];

// 서비스 > 약관 관리
export const term_status = [
  { id: 'REGIVATION', text: 'yellow' },
  { id: 'TEMP', text: 'gray' },
  { id: 'DEPLOIED', text: 'blue' },
];
// 서비스 > 포인트 지급관리
export const point_transaction_status = [
  { id: 'LOAD_FAIL', text: 'red' },
  { id: 'LOAD', text: 'skyblue' },
  { id: 'REGISTERED', text: 'green' },
];
// 서비스 > 바코드 관리
export const barcode_status = [
  { id: 'WAITING', text: 'default' },
  { id: 'REGISTERED', text: 'blue' },
  { id: 'UNREGISTERED', text: 'red' },
];
export const barcode_status_hex = [
  { id: 'WAITING', text: '#CCCCCC' },
  { id: 'REGISTERED', text: '#388E3B' },
  { id: 'UNREGISTERED', text: '#F04848' },
];
export const barcode_category_color_hex = [
  { id: 'PURPLE', text: '#BEA9ED' },
  { id: 'ORANGE', text: '#F98E5A' },
  { id: 'GRAY', text: '#A6A4A4' },
];
export const barcode_category_color_bg = [
  { id: 'PURPLE', text: '#D8CBF4' },
  { id: 'ORANGE', text: '#FBAE8A' },
  { id: 'GRAY', text: '#F1EEE1' },
];
export const barcode_category_color_border = [
  { id: 'PURPLE', text: '#BEA9ED' },
  { id: 'ORANGE', text: '#F98E5A' },
  { id: 'GRAY', text: '#E2D7C4' },
];
// 앱관리 > 알림
export const alarm_status = [
  { id: 'REGERVVATION', text: 'yellow' },
  { id: 'ING', text: 'blue' },
  { id: 'COMPLETE', text: 'default' },
];

// ---- 컨텐츠관리 -----
// 틴즈경제뉴스관리
export const blog_status = [
  { id:'NORMAL', text:'green' },
  { id:'HIDDEN', text:'red' },
]
export const post_type = [
  { id:'NORMAL', text:'default' },
  { id:'HOT', text:'red' },
  { id:'SAMPLE', text:'brown' },
]

// CX > 회원조회
export const cs_user_status_name = [
  // { id:'ready', text:'대기중' },
  // { id:'active', text:'활성화' },
  // { id:'quit', text:'탈퇴' },
  // { id:'inacitve', text:'휴면' },
  // { id:'restricted', text:'제재' },
  { id: 'PENDING', text: 'yellow' },
  { id: 'REGISTERED', text: 'green' },
  { id: 'UNREGISTERED', text: 'red' },
  { id: 'EXPIRED', text: 'gray' },
];

// CX > 회원조회
export const cs_family_status = [
  { id: 'REGISTERED', text: 'skyblue' },
  { id: 'UNREGISTERED', text: 'red' },
  { id: 'PENDING', text: 'yellow' },
  { id: 'IMPOSSIBLE', text: 'gray' },
  { id: 'DENY', text: 'orange' },
];
export const cs_user_role = [
  { id: 'PARENTS', text: 'skyblue' },
  { id: 'CHILD', text: 'yellow' },
];
export const cs_user_role_korean = [
  { id: '부모', text: 'skyblue' },
  { id: '부모 (대표)', text: 'skyblue' },
  { id: '자녀', text: 'yellow' },
];
export const cs_transaction_type = [
  { id: 'WITHDRAW', text: 'red' },
  { id: 'DEPOSIT', text: 'skyblue' },
];
export const cs_transaction_status = [
  { id: 'REGISTERED', text: 'skyblue' },
  { id: 'UNREGISTERED', text: 'red' },
];
export const cs_card_transaction_type = [
  { id: 'PAYMENT', text: 'skyblue' },
  { id: 'CANCEL', text: 'red' },
];
export const cs_trans_label = [
  { id: 'EXPORT', text: 'red' },
  { id: 'IMPORT', text: 'skyblue' },
];
// 가상계좌 발급내역 > 가상계죄상태
export const cs_virtual_account_status = [
  { id: 'REGISTERED', text: 'blue' },
  { id: 'UNREGISTERED', text: 'default' },
  { id: 'SUSPENDED', text: 'orange' },
  { id: 'BLOCKED', text: 'red' },
];
export const cs_re_delivery_type = [
  { id: 'RE_DELIVERY', text: 'green' },
  { id: 'DISCARD', text: 'red' },
];
export const cs_response_type = [
  { id: 'FAIL', text: 'red' },
  { id: 'SUCCESS', text: 'blue' },
];
export const cs_response_status = [
  { id: 'RE_DELIVERY_REQUEST', text: 'yellow' },
  { id: 'RE_DELIVERY_RESPONSE', text: 'skyblue' },
  { id: 'DISCARD_REQUEST', text: 'yellow' },
  { id: 'DISCARD_RESPONSE', text: 'skyblue' },
];
export const cs_taxreturn_status = [
  { id: 'USER_REQUEST', text: 'yellow' },
  { id: 'PUBLISHING', text: 'skyblue' },
  { id: 'SENDING', text: 'green' },
  { id: 'COMPLETE', text: 'default' },
];
// 송금/결제한도 상태
export const cs_transaction_limit_status = [
  { id: 'NONE', text: '' },
  { id: 'REGISTERED', text: 'skyblue' },
  { id: 'UNREGISTERED', text: 'default' },
];
// 블랙리스트 타입
export const cs_block_type = [
  { id: 'NOT_ALLOW_USE_APP', text: 'blue' },
  { id: 'USER_JOIN_BLOCK', text: 'orange' },
  { id: 'USER_JOIN_BLOCK_BY_THREE_DAY', text: 'yellow' },
  { id: 'USER_EXIT_BLOCK', text: 'purple' },
];
// 블랙리스트 상태
export const cs_block_status = [
  { id: 'BLOCK', text: 'red' },
  { id: 'UNBLOCK', text: 'skyblue' },
];
export const cs_block_boolean_status = [
  { id: true, text: 'red' },
  { id: false, text: 'skyblue' },
];
// 카드신청서상태
export const card_apply_status = [
  { id: 'WRITING', text: 'yellow' },
  { id: 'EXPIRED', text: 'default' },
  { id: 'CONFIRM', text: 'lightgreen' },
  { id: 'INVALIDATION_DELETE', text: 'default' },
  { id: 'WAIT', text: 'green' },
  { id: 'CANCELED', text: 'default' },
  { id: 'REGISTERED', text: 'blue' },
  { id: 'UNREGISTERED', text: 'orange' },
];

// 가맹점 관리 > 가맹점 관리
export const store_status = [
  { id: 'REGISTERED', text: 'blue' },
  { id: 'UNREGISTERED', text: 'red' },
];

// 가맹점 관리 > 가맹 계약 변경요청
export const contract_type = [
  { id: 'REQUESTED', text: 'green' },
  { id: 'APPROVED', text: 'blue' },
  { id: 'REJECTED', text: 'red' },
];

// 가맹점 관리 > 개별 거래 조회
export const trade_type = [
  { id: 'READY', text: 'default' },
  { id: 'INFO', text: 'default' },
  { id: 'APPROVE', text: 'green' },
  { id: 'CONFIRM', text: 'green' },
  { id: 'ABORTED', text: 'orange' },
  { id: 'CANCELED', text: 'orange' },
  { id: 'EXPIRED', text: 'yellow' },
];

// 가맹점 관리 > 정산 관리
export const settle_status = [
  { id: 'UNPAID', text: 'orange' },
  { id: 'PAID', text: 'green' },
];
export const settled_type = [
  { id: 'RECEIPT', text: 'green' },
  { id: 'CARRY', text: 'gray' },
  { id: 'REFUND', text: 'red' },
];

// 직원관리 > 회원 권한
export const member_role = [
  { id: 'ADMIN', text: 'red' },
  { id: 'USER', text: 'green' },
];

// 직원관리 > 회원 권한
export const member_login_status = [
  { id: 'OK', text: 'primary' },
  { id: 'LOCKED', text: 'red' },
  { id: 'NEED_MFA', text: 'orange' },
];

// 직원관리 > 회원 권한 아이콘
export const member_login_status_icon = [
  { id: 'OK', text: 'PRIMARY_COLOR' },
  { id: 'LOCKED', text: 'RED_COLOR' },
  { id: 'NEED_MFA', text: 'ORANGE_COLOR' },
];

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const scheduleTypeColorDataList = [
  { id: 'GENERAL', text: '#FFD82A' },
  { id: 'CONTENT', text: '#3DBF69' },
  { id: 'APP', text: '#00CA2C' },
  { id: 'PAGE', text: '#6CDFC3' },
  { id: 'BANK', text: '#F04848' },
];

export const colorDataList = {
  // ** (더미)상태컬러
  dummy_status: dummyStatusColorDataList,
  dummyStatusIconColorDataList: dummyStatusIconColorDataList,
  dummy_status_color: dummy_status_color,
  dummy_status2: dummy2StatusColorDataList,

  common_status: common_status,

  user_status_name: user_status_name,

  membership_type: membership_type,
  membership_purchase_method_type: membership_purchase_method_type,
  membership_payment_status: membership_payment_status,

  term_status: term_status,

  point_transaction_status: point_transaction_status,

  barcode_status: barcode_status,
  barcode_status_hex: barcode_status_hex,
  barcode_category_color_hex: barcode_category_color_hex,
  barcode_category_color_bg: barcode_category_color_bg,
  barcode_category_color_border: barcode_category_color_border,
  
  blog_status: blog_status,
  post_type: post_type,

  cs_family_status: cs_family_status,
  cs_user_status_name: cs_user_status_name,
  cs_user_role: cs_user_role,
  cs_user_role_korean: cs_user_role_korean,
  cs_transaction_type: cs_transaction_type,
  cs_transaction_status: cs_transaction_status,
  cs_card_transaction_type: cs_card_transaction_type,
  cs_trans_label: cs_trans_label,
  cs_virtual_account_status: cs_virtual_account_status,
  cs_re_delivery_type: cs_re_delivery_type,
  cs_response_type: cs_response_type,
  cs_response_status: cs_response_status,
  cs_taxreturn_status: cs_taxreturn_status,
  cs_transaction_limit_status: cs_transaction_limit_status,

  cs_block_type: cs_block_type,
  cs_block_status: cs_block_status,
  cs_block_boolean_status: cs_block_boolean_status,

  card_apply_status: card_apply_status,

  store_status: store_status,

  contract_type: contract_type,

  trade_type: trade_type,

  settle_status: settle_status,
  settled_type: settled_type,

  alarm_status: alarm_status,

  member_role: member_role, // 회원권한
  member_login_status: member_login_status, // 회원 로그인 상태
  member_login_status_icon: member_login_status_icon, // 회원 로그인 상태
  schedule_type: scheduleTypeColorDataList, // 스케줄 타입
};
